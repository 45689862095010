<template>
  <section id="dashboard">
    <b-row>
      <b-col>
        <h2>Assign Patients To Clinicians</h2>
      </b-col>
    </b-row>

    <b-row class="content-header">
      <b-col
        v-if="!viewMode"
        md="12"
        class="mb-2 text-right"
      >
        <button
          class="btn btn-secondary mr-3"
          type="button"
          @click="cancelPatient"
        >
          Cancel
        </button>
        <button
          class="btn btn-success"
          type="button"
          @click="saveAssignedClinician"
        >
          Save
        </button>
      </b-col>
    </b-row>

    <div
      v-if="viewMode"
      class="container mt-3 bg-white rounded p-5"
    >
      <h3 class="mb-3">
        All Patients
      </h3>
      <b-row class="match-height">
        <b-col>
          <div
            v-if="patients.length < 1"
            class="alert alert-info p-3"
          >
            No patients found.
          </div>
          <div v-else>
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID <b-icon-arrow-up /></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Clinician</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in patients"
                    :key="item.id"
                  >
                    <td>{{ item.uuid }}</td>
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.clinicians.length > 0 ? item.clinicians[0].name : "Not Assigned" }}</td>
                    <td>
                      <button
                        v-if="item.clinicians.length > 0"
                        class="btn btn-danger btn-block"
                        type="button"
                        @click="UnAssignClinician(item)"
                      >
                        Un-assign Clinician
                      </button>
                      <button
                        v-else
                        class="btn btn-primary btn-block"
                        type="button"
                        @click="assignClinician(item)"
                      >
                        Assign Clinician
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-model="args.page"
                :total-rows="args.total"
                :per-page="args.size"
                align="center"
                class="my-1"
                @change="getPatients"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="container mt-3 bg-white rounded p-5"
    >
      <h3 class="mb-3">
        Assign Clinician
      </h3>
      <b-row class="match-height">
        <b-col>
          <div class="form-group mb-3">
            <label for="patientFName">Patient Name</label>
            <input
              id="patientFName"
              type="text"
              disabled
              class="form-control"
              placeholder="Enter patient name"
              :value="`${patient.first_name} ${patient.last_name}`"
            >
          </div>
          <div class="form-group mb-3">
            <label>Patient Clinician</label>
            <select
              id="patientClinician"
              v-model="clinician"
              class="form-control"
            >
              <option
                v-for="item in clinicians"
                :key="item.uuid"
                :value="item.uuid"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </b-col>
      </b-row>
    </div>

  </section>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      patients: [],
      clinicians: [],
      clinician: null,
      viewMode: true,
      patient: {
        uuid: '',
        first_name: '',
        last_name: '',
        reports_count: 0,
        clinician: {
          uuid: '',
          name: '',
        },
      },
      sort: 'asc',
      filter: '',
      args: {
        page: 1,
        size: 20,
        total: 100,
      },
    }
  },
  mounted() {
    this.getPatients()
    this.getClinicians()
  },
  methods: {
    getPatients() {
      useJwt.getPatients(this.args).then(response => {
        this.patients = response.data.data
        this.args.total = response.data.links.last_page * this.args.size
      })
    },
    getClinicians() {
      const args = {
        page: 1,
        size: 500,
        sort: this.sort,
        filter: this.filter,
      }
      useJwt.getClinicians(args).then(response => {
        this.clinicians = response.data.data
      })
    },
    assignClinician(patient) {
      this.viewMode = false
      this.patient = patient
    },
    cancelPatient() {
      this.viewMode = true
      this.patient = {}
    },
    saveAssignedClinician() {
      const pUuid = this.patient.uuid
      const cUuid = this.clinician
      useJwt.assignClinician(pUuid, cUuid).then(() => {
        this.viewMode = true
        this.patient = {}
        this.getPatients()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Patients',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Clinician assigned successfully',
          },
        })
      },
      error => {
        let message
        if (error.response.status === 400) {
          const keys = Object.keys(error.response.data.error)
          if (keys.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.error[keys[0]][0]
          } else {
            message = error.response.data.details
          }
        } else {
          message = 'Something went wrong. Please try later.'
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Patients',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: message,
          },
        })
      })
    },
    UnAssignClinician(patient) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const pUuid = patient.uuid
          const cUuid = patient.clinicians[0].uuid
          useJwt.unAssignClinician(pUuid, cUuid).then(() => {
            this.getPatients()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Patients',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Clinician un-assigned successfully',
              },
            })
          },
          error => {
            let message
            if (error.response.status === 400) {
              const keys = Object.keys(error.response.data.error)
              if (keys.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                message = error.response.data.error[keys[0]][0]
              } else {
                message = error.response.data.details
              }
            } else {
              message = 'Something went wrong. Please try later.'
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Patients',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: message,
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
